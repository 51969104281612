import request from '../../utils/request.js';

// 查询假期余额管理列表
export function listHoliday (params) {
  return request().get(`/api/attendance-manage/v1/fdm/holiday/list`, { params })
}

// 导入数据
export function importHoliday (data) {
  return request().post(`/api/attendance-manage/v1/fdm/holiday/import`, data)
}
// 编辑数据
export function editHoliday (params) {
  return request().post(`/api/attendance-manage/v1/fdm/holiday/update/apAttHoliday`, {
    ...params,
  })
}
// 编辑数据
export function removeHoliday (params) {
  return request().post(`/api/attendance-manage/v1/fdm/holiday/remove/apAttHoliday/` + params)
}
// 导出假期余额管理
export function exportHoliday (params) {
  return request().get(`/api/attendance-manage/v1/fdm/holiday/export`, { params })
}
// 下载模板
export function getTemplate(params) {
  return request().get(`/api/attendance-manage/v1/fdm/holiday/getTemplate`, { params })
}

export function findStatus() {
  return request().get(`/api/attendance-manage/v1/fdm/holiday/excelimport/findstatus`)
}

export function rollback() {
  return request().post(`/api/attendance-manage/v1/fdm/holiday/excelimport/rollback`)
}

export function getError() {
  return `/api/attendance-manage/v1/fdm/holiday/excelimport/getError?fileUrl=`
}